.GuestActionSheetMenu {
   border-top-left-radius: 25px;
   border-top-right-radius: 25px;
}

.GuestActionSheetMenu h3 {
    padding-top: 1em;
    margin-left: 1em;
    font-size: large;
}

.GuestActionSheetMenu .action-sheet-body {
    margin-top: 1em;
}

.GuestActionSheetMenu .action-sheet-message {
    margin: 0 1em 1em 1em;
    font-size: medium;
    font-weight: normal;
    color: var(--secondary-fg-color);
}

.GuestActionSheetMenu li {
    justify-content: center;
}

.GuestActionSheetMenu .ant-menu-title-content {
    width: unset !important;
}