.ProfileListItem {
   display: flex;
   flex-direction: row;
   align-items: center;
   padding: 1em;
   border-bottom: 1px solid var(--tertiary-fg-color); /* #ddd; */
   margin-bottom: 2px;

   background-color: var(--secondary-bg-color);
}

.ProfileListItem .avatar,
.ProfileListItem .navIcon {
   flex-grow: 0;
}

.ProfileListItem .navIcon {
   height: 1em;
   width: 1em;
   /* color: var(--tertiary-fg-color); */
   color: var(--adm-color-light);
   font-size: 19px;
}

/* .ProfileListItem .avatar {
   margin-right: 0.5em;
} */

.ProfileListItem .content {
   flex-grow: 1;
   /* padding-right: 0.5em; */
}

.ProfileListItem .header .title,
.ProfileListItem .header .extra {
   white-space: nowrap;
   overflow-x: clip;
}

.ProfileListItem .header .title {
   font-size: large;
   /* margin-left: 1em; */
}

.ProfileListItem .content .body {
   all: unset;
   border: 0;
   font-size: medium;
   font-weight: 100 !important;
   color: var(--secondary-fg-color);
}

.ProfileListItem .ant-avatar img {
   width: 52px;
   height: 52px;
}

.ProfileListItem .content {
   padding-left: 1em;
}

.ProfileListItem .guest-profile {
   background-color: #ccb9e1; /*"#87d068";*/
}
