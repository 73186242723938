.StartPagePickerPage {
    margin-top: 90px;
}


.StartPagePickerPage h4 {
    margin: 10px 0 10px 0;
}

.StartPagePickerPage .anticon-check {
    color: var(--apple-link-color);
    margin-right: 0.5em;
}

.StartPagePickerPage .adm-list-body {
    border-radius: 25px;
    margin-left: 1em;
    margin-right: 1em;
    border: 0;
}
