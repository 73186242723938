.InstallAppPopover {
    z-index: 20000;
    position: absolute;
    bottom: 10px;
    left: 50%;
    margin-left: -98px;
    background-color: var(--tertiary-bg-color);
    border-radius: 20px;
    padding: 5px 30px 15px 30px;
    box-shadow: 0 0 15px 0px rgba(0, 0, 0, 0.3);
 }
 
 .InstallAppPopover .title {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid var(--tertiary-fg-color);
    margin: -5px -25px 10px -25px;    
 }
 
 .InstallAppPopover .title > div {
    color: var(--primary-fg-color);
    text-align: center;
    font-size: large;
    font-weight: bold;
 }

 .InstallAppPopover .title .close-button {
     position: absolute;
     right: 7px;
     top: -9px;
 }

 .InstallAppPopoverContent {
    display: flex;
    flex-direction: column;
    align-items: center;
 }
 
 .InstallAppPopoverContent p:first-child {
    margin: 0;
 }
 
 .InstallAppPopoverContent  .ShareIcon {
    width: 48px;
    /* fill: var(--apple-link-color); */
    fill: var(--secondary-fg-color);
 }
 
 .InstallAppPopoverContent  .FingerPoint {
    margin-top: 5px;
    width: 48px;
    fill: var(--secondary-fg-color);
 }
 