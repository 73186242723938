.ActionSheetMenu {   
   position: fixed;
   bottom: 0px;
   margin-bottom: 75px;
}

@media (min-width: 420px) {
   .ActionSheetMenu /*.action-sheet*/{
      width: 400px !important;
      /* centering */
      margin-left: -200px;
      left: 50%;
   }
}

@media (max-width: 419px) {
   .ActionSheetMenu /*.action-sheet*/{
      width: 95% !important;
      left: 0;
   }
}

.ActionSheetMenu .MenuItemLink a {
   color: var(--apple-link-color) !important;
}

.ActionSheetMenu /*.action-sheet*/{
   z-index: 8000 !important;
}

.ActionSheetMenu .action-sheet-body {
   background-color: var(--tertiary-bg-color);
   border-radius: 10px;
}

.ActionSheetMenu .action-sheet-message,
.ActionSheetMenu .list-body,
.ActionSheetMenu .list-item-top {
   border-top-left-radius: 10px;
   border-top-right-radius: 10px;
}

.ActionSheetMenu .action-sheet-button-list {
   border-radius: 10px;
   border-bottom-left-radius: 10px;
   border-bottom-right-radius: 10px;
}

.ActionSheetMenu .action-sheet-button-list .title {
   padding-top: 5px;
   font-size: large;
}

.ActionSheetMenu .action-sheet-cancel-button {
   width: 100%;
   height: 2.95em;
   line-height: 2em;
   color: #4693ff;
   border-radius: 10px;
   font-size: large;
   font-weight: bold;
   background-color: var(--tertiary-bg-color);
   margin-top: 10px;
}

.ActionSheetMenu .action-sheet-button-list,
.ActionSheetMenu .list-item,
.ActionSheetMenu .list-line,
.ActionSheetMenu .list-item .list-line .list-content {
   background-color: var(--tertiary-bg-color);
}

@media (prefers-color-scheme: dark) {
   /* some other selector prevents this */
   .ActionSheetMenu .list-body {
      border-top-color: red !important;
      border-bottom-color: red !important;
   }
   .ActionSheetMenu .list-line {
      border-top-color: purple !important;
      border-bottom-color: purple !important;
   }
}

.ActionSheetMenu .ant-menu-item {
   line-height: 22px;
   display: flex;
   align-items: center;
   color: var(--primary-fg-color);
}

.ActionSheetMenu .ant-menu-item span {
   font-size: 16px;
   width: 24px;
   height: 24px;
}

.ActionSheetMenu span[role="img"] svg {
   width: 22px;
   height: 22px;
}

.ActionSheetMenu .ant-divider-horizontal {
   margin: 0;
}
