.helpfulsContent {
   padding: 20px 15px 0 15px;
}

.HelpfulsCard .body {
   border-bottom: 1px solid var(--tertiary-fg-color); /* #ddd;*/
}

.helpfulsContent .ant-timeline-item-head {
   background-color: transparent;
}

.helpfulsContent .ant-timeline-item-content {
   color: var(--secondary-fg-color);
}

@media (prefers-color-scheme: dark) {
   .helpfulsContent .ant-timeline-item-tail {
      border-color: var(--tertiary-fg-color);
   }
}
