.FindYourAccountPage {
   padding: 110px 20px 10px 20px;
   margin-bottom: 75px;
}

.FindYourAccountPage h2 {
    color: var(--primary-fg-color);
    font-size: x-large;
    margin-bottom: 0.5em;
 }
 
.FindYourAccountPage .instruction {
   margin-bottom: 2em;
   font-size:larger;
}


