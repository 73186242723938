
.ToolBarActionSheetMenu .action-sheet-button-list,
.ToolBarActionSheetMenu .adm-list,
.ToolBarActionSheetMenu .adm-list-body,
.ToolBarActionSheetMenu .adm-list-body-inner,
.ToolBarActionSheetMenu .ProfileListItem {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
 }

 .ToolBarActionSheetMenu .ant-menu-title-content {
    color: var(--primary-fg-color);
 }

 .ToolBarActionSheetMenu li svg {
    color: var(--primary-fg-color);
 }