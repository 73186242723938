@media print {
   .MasterIndexPage .AlphabetList {
      display: none;
   }
   .MasterIndexPage .mag-lens {
      width: 0;
      height: 0;
      display: none;
   }
   .MasterIndexPage .adm-list-header {
      background-color: #f5f5f9;
   }
   .MasterIndexPage .adm-list-brief {
      display: block;
   }
}

@media screen {
   .MasterIndexPage {
      margin-bottom: 100px;
      padding-top: 95px;
      padding-bottom: 20px;
   }
   .MasterIndexPage .adm-list-brief {
      display: none;
   }
}
.MasterIndexPage .adm-list-header {
   font-weight: bold;
   color: var(--primary-fg-color);
   padding: 0.4em 15px 0.4em 15px;
}

.MasterIndexPage .mag-lens {
   /* display: block; */
   /* position: relative; */
   display: inline-block;
   line-height: 2em;
   position: fixed;
   color: black;
   font-weight: strong;
   font-size: large;
   text-align: center;
   vertical-align: middle;
   top: 693.1500244px;
   right: 10px;
   z-index: 9000;
   width: 40px;
   height: 40px;

   border: 2px inset gray;
   background-color: rgba(255, 248, 220, 0.7);
   /*background-color: transparent;*/
   backdrop-filter: blur(15px);
   border-radius: 25px;

   /* background-image: url("../images/mag-lens.png");
   background-size: 40px 40px;
   background-repeat: no-repeat; 
   opacity: 0.7; */
}

/* .MasterIndexPage .mag-lens::after {
    display: inline-block;
    line-height: 2em;
    content: "W";
    position: absolute;
    color: black;
    font-weight: strong;
    font-size: large;
    text-align: center;
    vertical-align: middle;
    top: 60px;
    right: 0;
    z-index: 50000;
    width: 40px;
    height: 40px;
    background-image: url("../images/mag-lens.png");
    background-size: 40px 40px;
    background-repeat: no-repeat;
    opacity: 0.7;
} */

.MasterIndexPage .ant-empty {
   padding: 2em;
}
