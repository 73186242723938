@media screen {
   .AdminArticlePage {
      margin-bottom: 100px !important;
      padding: 95px 10px 100px 10px;
   }
}

@media print {
   .AdminArticlePage {
      padding: 10px 10px 100px 10px;
   }
}

@media (max-width: 401px) {
   .adm-popover .ArticleListItem .title,
   .adm-popover .ArticleListItem .extra {
      font-size: smaller;
   }
}

.AdminArticlePage form .field {
   display: flex !important;
}

.AdminArticlePage .article-category-autocomplete-popup {
   width: 90% !important;
   border: 1px solid var(--tertiary-fg-color);
   box-shadow: 3px 3px gray;
}

.AdminArticlePage h2 {
   color: var(--primary-fg-color);
}

.AdminArticlePage .am-textarea-label {
   color: var(--secondary-fg-color);
}

.AdminArticlePage .am-textarea-label span {
   display: block;
   font-style: italic;
   color: var(--tertiary-fg-color);
   margin-top: -0.9em;
}

.AdminArticlePage .instruction {
   color: var(--secondary-fg-color);
}

.AdminArticlePage a[role="button"] {
   margin-bottom: 5px;
}

.articleEditorForm {
   padding: 10px;
   min-width: 300px;
}

.articleEditorForm a[role="button"] {
   margin-top: 0.5em;
}

.articleEditorForm .am-textarea-item {
   font-weight: bold;
}

.articleEditorForm .am-list-item {
   margin-bottom: 10px;
}

@media (prefers-color-scheme: light) {
   .articleEditorForm .am-list-item {
      border-bottom: 1px solid #ddd;
   }
}

.articleEditorForm .am-textarea-error {
   background-color: #fffdd0;
}

.articleEditorForm .am-textarea-error-extra {
   margin-top: auto;
   margin-bottom: auto;
}

.articleEditorForm fieldset {
   margin-top: 25px;
   border: none;
   padding: 0;
}

.articleEditorForm .texteditor textarea {
   /* border: 1px outset gray; */
   padding: 2px;
}

.articleEditorForm .am-textarea-control {
   padding-right: 10px;
}

.articleEditorForm .am-image-picklist-list {
   padding-left: 0;
}

/* .related-to-header {
   display: flex;
   justify-content: space-between;
   align-items: center;
}

.related-to-header div {
   display: flex;
   justify-content: space-between;
   align-items: center;
   cursor: pointer;
}

.related-to-header .add-related-to-button {
   cursor: pointer;
}

.related-to-header .relation-label {
   display: inline-block;
   font-weight: bold;
   color: black;
   font-size: 17px;
   line-height: 44px;
} */

.related-to-list .am-list-header {
   padding: 0;
}

.related-to-list .am-list-extra {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: flex-end;
}

.related-to-list .am-list-extra svg {
   cursor: pointer;
}
