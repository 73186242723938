/*@import '~antd/dist/antd.dark.css';*/

@media print {
   html,
   body
   /* .adm-drawer,
   .adm-drawer-content */ {
      background-color: transparent;
      display: block !important;
      width: auto !important;
      height: auto !important;
      overflow: visible !important;
   }

   .star-icon,
   .ToolBar,
   .star-icon-filled {
      display: none;
   }
   .copyrightFooter {
      text-align: justify;
      font-size: smaller;
      padding: 20px;
   }
}


@media (prefers-color-scheme: light) {
   :root {
      --apple-link-color: #4693ff;
      --error-color: red;
      --form-input-invalid-background-color: #fffdd0;
      --brand-bg-color: #265780;
      --toolbar-fg-color: #eee8dd;

      --primary-bg-color: #f2f2f8;
      --primary-bg-color-50pct: rgba(242,242,248,0.5);
      --primary-fg-color: #000000;

      --secondary-bg-color: #ffffff;
      --secondary-fg-color: #777777;
      --tertiary-bg-color: #ffffff;
      --tertiary-fg-color: #ddd;  /* #8b8b8e */
   }
   .fdrag_item {
      border-bottom: 1px solid #dedede;
   }
   .ant-menu-item-disabled {
      color: rgba(0, 0, 0, 0.25) !important;
      fill: rgba(0, 0, 0, 0.25) !important;
   }
}

@media (prefers-color-scheme: dark) {
   :root {
      --apple-link-color: #4693ff;
      --error-color: red;
      --form-input-invalid-background-color: #fffdd0;
      --brand-bg-color: #265780;
      --toolbar-fg-color: #eee8dd;

      --primary-fg-color: #ffffff;
      --secondary-fg-color: #9998a2;
      --tertiary-fg-color: #3c3b43;

      --primary-bg-color: #000000;
      --primary-bg-color-50pct: rgba(238,232,221,0.5);
      --secondary-bg-color: #1c1c1e;
      --tertiary-bg-color: #39383d;

      --adm-color-border: #3c3b43;
      --adm-color-background: var(--secondary-bg-color);
      --adm-color-text: var(--secondary-fg-color);
      
      /* --primary-fg-color: #eee8dd;
      --secondary-fg-color: #848484;
      --tertiary-fg-color: #4f4f52;

      --primary-bg-color: #000000;
      --primary-bg-color-50pct: rgba(238,232,221,0.5);
      --secondary-bg-color: #181818;
      --tertiary-bg-color: #252525; */
   }
   .adm-toast-main {
      background-color: var(--tertiary-bg-color) !important;
   }
   /* .adm-list {
      border-top: none;
      border-bottom: none;
   } */
   .adm-button {
      border: none;
   }
   .ant-divider,
   .ant-divider-horizontal {
      border-color: var(--tertiary-fg-color);
   }
   .ant-menu-item-disabled {
      color: rgba(255, 255, 255, 0.25) !important;
      fill: rgba(255, 255, 255, 0.25) !important;
   }
   .ant-menu-item {
      fill: var(--primary-fg-color);
   }
   .fdrag_item {
      border-bottom: 1px solid var(--tertiary-fg-color);
   }
   .adm-switch .checkbox,
   .adm-switch .checkbox:before {
      background: #39383d;
   }
   @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
      html:not([data-scale]) .adm-list-body::before,
      html:not([data-scale]) .adm-list-body::after,
      div:not(:last-child) .adm-list-line::after {
         background-color: var(--tertiary-fg-color) !important;
      }
   }
   .adm-picker-col-mask {
      background-image: linear-gradient(
            to bottom,
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0.1)
         ),
         linear-gradient(
            to top,
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0.1)
         );
   }
   .adm-picker-col-item-selected {
      color: var(--primary-fg-color);
   }
   /*
   .adm-picker-col-indicator {
      background-color: var(--primary-bg-color);
   }
   */
}

/* DRAG-N-DROP FRAMEWORK */
.fdrag_item {
   padding-bottom: 5px;
}
/* DRAG-N-DROP FRAMEWORK */



/** ant-design-mobile action sheet */
.adm-action-sheet {
   margin-bottom: 75px;
}
.adm-popup-body {
   max-width: 95vw;
   margin-left: auto;
   margin-right: auto;
}
.adm-popup-body, 
.adm-action-sheet,
.adm-action-sheet-extra {
   border-top-right-radius: 25px;
   border-top-left-radius: 25px;
}
.adm-popup-body {
   transform: translate(10px,0%);
}
.adm-action-sheet .adm-action-sheet-extra {
   flex-direction: column;
}
/** ant-design-mobile action sheet */



/** these rules don't seem to work after lib upgrade */
.adm-action-sheet-button-list-item:last-child,
.adm-action-sheet-cancel-button {
   border-top: none; /*1px solid var(--tertiary-bg-color);*/
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
   html:not([data-scale]) .adm-action-sheet-button-list-item:last-child::before {
      background-color: transparent;
   }
}
.adm-action-sheet {
   padding: 15px;
   margin-bottom: 60px;
}
.adm-action-sheet,
.adm-action-sheet-body,
.adm-action-sheet-content {
   background-color: transparent;
}
.adm-action-sheet-title {
   font-size: 20px;
   margin-bottom: 0;
   padding-top: 10px;
   color: var(--primary-fg-color);
   border-top-left-radius: 10px;
   border-top-right-radius: 10px;
   background-color: var(--tertiary-bg-color);
}
.adm-action-sheet-button-list-item {
   background-color: var(--secondary-bg-color);
}
div[data-seed="3"] .adm-action-sheet-button-list-item:nth-child(2) {
   border-bottom-left-radius: 10px;
   border-bottom-right-radius: 10px;
}
.adm-action-sheet-message {
   padding-top: 15px;
   margin-top: 0;
   margin-bottom: 0;
   padding-bottom: 15px;
}
.adm-action-sheet-message,
.adm-action-sheet-button-list-item {
   /* background-color: #fff; */
   background-color: var(--tertiary-bg-color);
   color: var(--secondary-fg-color);
}
.adm-action-sheet-button-list-item {
   overflow: hidden;
   /* border: 1px solid #ddd !important; */
   color: var(--apple-link-color);
}
.adm-action-sheet-cancel-button {
   padding-top: 0 !important;
   padding-bottom: 0 !important;
   margin-top: 10px;
   border-radius: 10px;
}
.adm-action-sheet-cancel-button::before,
.adm-action-sheet-cancel-button-mask,
.adm-action-sheet-cancel-button-mask::before,
.adm-action-sheet-cancel-button-mask::after {
   display: none;
}
/** these rules don't seem to work after lib upgrade */



@media screen {
   body {
      background-color: var(--primary-bg-color);
   }
   .copyrightFooter {
      display: none;
   }
}

body {
   margin: 0;
   font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   color: var(--primary-fg-color);
   overflow-x: hidden;
   /*overflow-y: hidden;*/
   overflow-y: auto;
}

.unselectable {
   -webkit-touch-callout: none;
   -webkit-user-select: none;
   -khtml-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
}

.ant-menu {
   background-color: transparent;
}

.adm-popover-mask {
   top: 100px !important;
}

/*.adm-picker-col-mask,*/
.adm-popover-mask,
.adm-action-sheet-mask {
   -webkit-backdrop-filter: blur(6px);
   backdrop-filter: blur(6px);
   transition:all .5s cubic-bezier(0.5,-0.5,0.3,1.4);
}

.adm-popover-inner,
.adm-popover-arrow {
   /* background-color: #f2f2f8; */
   background-color: var(--secondary-bg-color);
   color: var(--seconary-fg-color);
}

.adm-popover-inner-wrapper {
   background-color: transparent;
}

/* fixed bug in ANT library with carat-r arrow vertical align */
.adm-list-arrow {
   margin-top: auto !important;
   margin-bottom: auto !important;
}

.adm-list-header {
   color: var(--secondary-fg-color);
}

.adm-list-header h2,
.adm-list-header h3 {
   color: var(--primary-fg-color);
}

.adm-list-item {
   background-color: var(--secondary-bg-color);
   /* padding-left: 0; */
}

.adm-list-body {
   background-color: var(--secondary-bg-color);
}

.adm-list-line {
   background-color: var(--secondary-bg-color);
}

.adm-list-item .adm-list-line .adm-list-content {
   background-color: var(--secondary-bg-color);
   color: var(--primary-fg-color);
   padding-left: 0.5em;
}

.adm-list-item img {
   height: auto;
   width: 76px;
}

/* .adm-list-content {
   padding-left: 0.5em;
   color: var(--primary-fg-color);
} */

.adm-list-item .adm-list-line .adm-list-brief {
   color: var(--secondary-fg-color);
}

.adm-button-primary {
   background-color: var(--brand-bg-color);
   color: #f2f2f8;
}

.adm-button-warning {
   background-color: #c6373b;
   color: #f2f2f8;
}

.adm-button--normal {
   background-color: var(--secondary-bg-color);
   color: var(--apple-link-color);
}

.star-icon svg {
   width: 32px;
   height: 32px;
}

.star-icon-filled {
   color: #f3a954; /* #FFD700; */
}


.ant-menu-vertical {
   border: none !important;
}

.adm-button {
   border-radius: 25px;
}

code {
   font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
}

.errorText {
   color: var(--error-color);
}

.offlineMessage {
   color: gray;
   text-align: center;
   margin-top: 1em;
}

