/* .ArticleListItem.adm-list-item
{
    padding-left: 1em !important;
}

.ArticleListItem.adm-list-item .adm-list-line .adm-list-content {
    padding-left: 0;
}

.ArticleListItem.adm-list-item .adm-list-thumb:first-child {
    margin-right: 0.5em !important;
 }
 */
.ArticleListItem .adm-image {
    margin-left: -1em !important;
    width: 76px  !important;
    height: 76px !important;
    overflow: hidden !important;
}

/* ================================== */

.ArticleListItem {
    display: flex;
    flex-direction: row;
    align-items: center;    
    padding: 0 1em 0 1em;
    border-bottom: 1px solid var(--tertiary-fg-color); /* #ddd; */
    margin-bottom: 2px;
}

.ArticleListItem .avatar,
.ArticleListItem .navIcon {
    flex-grow: 0;
    flex-shrink: 0;
}

.ArticleListItem .navIcon {
   height: 1em;
   width: 1em;
   color: var(--secondary-fg-color);
}

.ArticleListItem .avatar {
    margin-right: 0.5em;
}

.ArticleListItem .content {
    flex-grow: 1;
    padding: 0.75em 0.5em 0.75em 0;
}

.ArticleListItem .header .title,
.ArticleListItem .header .extra {
  white-space: nowrap;
  overflow-x: clip;
}

.ArticleListItem .content .body {
    all:unset;
    border: 0;
    font-size: small;
    font-weight: 100 !important;
    color: var(--secondary-fg-color);
}
