/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
@media print {
   a[role=button] {
      display:none;
   }
}
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

@media screen {
   .FavoritesPage {
      margin-bottom: 100px;
      padding-top: 95px;
      padding-bottom: 70px;
   }
}

.FavoritesPage .NoResults {
  /*   margin: 0 20px 0 20px; */
   font-size: medium;
}

/* .FavoritesPage .ant-empty {
   background-color: var(--primary-bg-color);
   margin-top: 20px;
} */

.FavoritesPage .am-list-header {
    padding-top: 9px;
}

.FavoritesPage .list-header {
   display: flex;
   justify-content: space-between;
   align-items: center;
}

.FavoritesPage .list-header h3 {
   margin: 0;
}

.FavoritesPage .editable-row {
   background-color: var(--secondary-bg-color);
   border-bottom: 1px solid var(--tertiary-fg-color); /* #dedede; */
}

.FavoritesPage .fdraglist {
    background-color: #646764;
}

.FavoritesPage .editable-row {
   display: flex;
   justify-content: flex-start;
   align-items: center;
   flex-wrap: nowrap;
   height: 50px;
}

.FavoritesPage .delete-button {
    height: 100%;
    display: flex;
    align-items: center;
    margin-left: 5px;
}
/* delete icon */
.FavoritesPage .circle-minus-delete-icon {
   /* flex-grow: 0;
   margin-left: 10px;
   margin-right: 10px;
   color: #c6373b; */
   flex-grow: 0;
   margin-left: 10px;
   margin-right: 10px;
   color: #c6373b;
   background-color: white;
   border-radius: 15px;
}

.FavoritesPage span[role="img"] svg {
   width: 22px;
   height: 22px;
}

/* hamburgler icon */
.FavoritesPage .hamburgler-icon {
   width: 17px;
   height: 17px;
   flex-grow: 0;
   margin-left: 10px;
   margin-right: 15px;
   fill: var(--tertiary-fg-color);
}

.FavoritesPage .editable-row .edit-text {
   flex-grow: 10;
   overflow-x: hidden;
}

.FavoritesPage .adm-button {
   background-color: var(--brand-bg-color);
   color: #f2f2f8;
}
