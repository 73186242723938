@media screen {
   .BrowseCategoriesPage {
      margin-bottom: 100px;
      padding-top: 95px;
      padding-bottom: 70px;
   }
}

.BrowseCategoriesPage .bubbleList {
   display: flex;
   flex-wrap: wrap;
}

.BrowseCategoriesPage .CategoryFrufru {
   cursor: pointer;
   width: 100vw;
   height: 100px;
   position: relative;
}

@media (max-width: 999px) {
   .BrowseCategoriesPage .CategoryFrufru {
      flex-basis: 100%;
   }
}

@media (min-width: 1000px) {
   .BrowseCategoriesPage .CategoryFrufru {
      flex-basis: 44%;
   }
}

@media (min-width: 1500px) {
   .BrowseCategoriesPage .CategoryFrufru {
      flex-basis: 29%;
   }
}

@media (min-width: 2000px) {
   .BrowseCategoriesPage .CategoryFrufru {
      flex-basis: 22%;
   }
}

@media (min-width: 2500px) {
   .BrowseCategoriesPage .CategoryFrufru {
      flex-basis: 17%;
   }
}

@media (min-width: 3000px) {
   .BrowseCategoriesPage .CategoryFrufru {
      flex-basis: 12%;
   }
}


/* @media (min-width: 420px) {
} */


.BrowseCategoriesPage h2 {
   color: var(--primary-fg-color);
   font-weight: bolder;
   font-size: x-large;
   margin: 10px 0 0 20px;
   width:100%;
}

/* .BrowseCategoriesPage fieldset {
   margin: 20px 20px 20px 20px;
} */

.BrowseCategoriesPage .am-list-content {
   padding-left: 1em;
}
