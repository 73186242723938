@media print {
   .PageHeader, .PageHeader .main-header {
      display: none !important;
   }
}

/* @media (prefers-color-scheme: dark) {
   .adm-nav-bar-back-arrow {
      color: var(--apple-link-color);
   }
} */

.PageHeader {
   /* flex: 0 0 auto; */
   position: fixed;
   top: 0;
   left: 0;
   width:100%;
   max-height: 95px;
   min-height: 45px;
   z-index: 4500;

   display: flex;
   flex-direction: column;
}

.PageHeader.online {
  background-color: var(--brand-bg-color);
}

.PageHeader.offline {
   background-image:  linear-gradient(#a63b2b,#802f23);
}

.main-header {
   color: #ffffff;
   background-color: transparent;
}

.PageHeader .am-icon-md {
   height: 32px;
   width: 32px;
}

.PageHeader .main-header .hamburgler-button {
   background-color: transparent;
   border: none;
   cursor: pointer;
}

.PageHeader .main-header .hamburgler-button svg {
   width: 1.5em;
   fill: var(--toolbar-fg-color);
}

.PageHeader .main-header .am-navbar-title {
   color: var(--toolbar-fg-color);
}

.PageHeader .main-header .am-navbar-right {
   margin-top: 5px;
}

.PageHeader .hamburgler-button::before {
   border: none !important;
}

.PageHeader .ShieldIcon {
   width: 32px;
   height: 32px;
}

