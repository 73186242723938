/* z-index design
   10 = background gradient
   20 = column overlay
   30 = selection overlay
   */

.SolarCycleTableData {
   width: 100%;
   height: 100%;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: stretch;
   flex: 2, 0, auto;
   column-gap: 0;
   flex-wrap: nowrap;
   margin-bottom: 0.5em;
   white-space: nowrap;
}

.SolarCycleCalendarColumn {
   height: 100%;
   width: 100%;
   background-color: transparent;
   display: flex;
   flex-wrap: nowrap;
   flex-direction: column;
   align-items: stretch;
   z-index: 20;
}

.SolarCycleCalendarColumn .selection {
   background-color: rgba(255, 255, 0, 0.3) !important;
}

.SolarCycleCalendarColumn .solarFlareIntensity {
   text-align: center;
   min-height: 126px;
   background-color: transparent;
   display: flex;
   align-items: flex-start;
   flex-direction: column;
   z-index: 20;
}

/*
.SolarCycleCalendarColumn .bar10pct,
.SolarCycleCalendarColumn .bar20pct,
.SolarCycleCalendarColumn .bar40pct,
.SolarCycleCalendarColumn .bar60pct,
.SolarCycleCalendarColumn .bar80pct,
.SolarCycleCalendarColumn .bar100pct {
   flex-basis: auto;
   flex-shrink: 0;
   width: 100%;
   z-index: 20;
}
*/

@media (prefers-color-scheme: light) {
   .SolarCycleCalendarColumn .selection-bar {
      background-color: #ffffad !important;
   }
}

@media (prefers-color-scheme: dark) {
   .SolarCycleCalendarColumn .selection-bar {
      background-color: #68681d !important;
   }
}

.SolarCycleCalendarColumn .solarFlareIntensity,
.SolarCycleCalendarColumn .cycleYearNum,
.SolarCycleCalendarColumn .gregorianYearRange,
.SolarCycleCalendarColumn .dimensionText,
.SolarCycleCalendarColumn .notes {
   border-bottom: 1px solid var(--tertiary-fg-color);
}

.SolarCycleCalendarColumn.zebra-stripe :not(.selection-bar) :not(.selection),
.SolarCycleCalendarColumn.zebra-stripe .solarCycleNum {
   background-color: var(--primary-bg-color);
}

.SolarCycleCalendarColumn:not(.zebra-stripe)
   :not(.selection-bar)
   :not(.selection),
.SolarCycleCalendarColumn:not(.zebra-stripe) .solarCycleNum,
.SolarCycleCalendarColumn:not(.zebra-stripe) .cycleYearNum,
.SolarCycleCalendarColumn:not(.zebra-stripe) .gregorianYearRange,
.SolarCycleCalendarColumn:not(.zebra-stripe) .yearLabel,
.SolarCycleCalendarColumn:not(.zebra-stripe) .dimensionText,
.SolarCycleCalendarColumn:not(.zebra-stripe) .notes {
   background-color: var(--secondary-bg-color);
}

.SolarCycleCalendarColumn .bar10pct {
   height: 90%;
   flex-grow: 0.9;
}

.SolarCycleCalendarColumn .bar20pct {
   height: 80%;
   flex-grow: 0.8;
}

.SolarCycleCalendarColumn .bar40pct {
   height: 60%;
   flex-grow: 0.6;
}

.SolarCycleCalendarColumn .bar60pct {
   height: 40%;
   flex-grow: 0.4;
}

.SolarCycleCalendarColumn .bar80pct {
   height: 20%;
   flex-grow: 0.2;
}

.SolarCycleCalendarColumn .bar100pct {
   height: 0%;
   flex-grow: 0;
   line-height: 0;
}

.SolarCycleCalendarColumn .solarCycleNum {
   text-align: center;
   /*min-height: 1.6em;*/
   color: var(--primary-fg-color);
   position: relative;
   top: -11.5em;
   left: 0.75em;
   width: 0;
   height: 0;
}

/* .SolarCycleCalendarColumn .solarCycleNum.selection {
   background-color: var(--secondary-bg-color);
} */

.SolarCycleCalendarColumn .cycleYearNum,
.SolarCycleCalendarColumn .gregorianYearRange,
.SolarCycleCalendarColumn .yearLabel,
.SolarCycleCalendarColumn .dimensionText,
.SolarCycleCalendarColumn .notes {
   text-align: center;
   z-index: 20;
   border-collapse: collapse;
   padding-left: 1em;
   padding-right: 1em;
}

.SolarCycleCalendarColumn .gregorianYearRange,
.SolarCycleCalendarColumn .dimensionText,
.SolarCycleCalendarColumn .notes {
   color: var(--secondary-fg-color);
}

.SolarCycleCalendarColumn .dimensionText span {
   background-color: transparent !important;
}

.SolarCycleCalendarColumn .cycleYearNum {
   color: #4bc500; /*var(--primary-fg-color); */
   font-weight: bold;
}

.SolarCycleCalendarColumn .yearLabel {
   display: block;
   background-color: transparent !important;
}
