@media (prefers-color-scheme: dark) {
   .loginPage .background-mask {
      background-color: rgba(0,0,0,0.85);
   }
}

.loginPage {
   width: 100vw;
   height: 100vh;
   background-image: url("../images/wood-board-tile.jpg");
   background-repeat: repeat, repeat;
}

.loginPage .background-mask {
   width: 100vw;
   min-height: 100vh;
   min-height: -webkit-fill-available;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   text-align: center;
   margin-bottom: 100px;
}

.loginPage .loginPanel {
   position: relative;
   width: 350px;
   background-color: var(--secondary-bg-color);
   color: var(--primary-fg-color);
   padding: 3em;
   -webkit-box-shadow: 0 0 10px #999;
   box-shadow: 0 0 10px #999;
}

.loginPanel h1 {
   color: var(--primary-fg-color);
   font-size: xx-large;
}


.loginPanel .submitButton {
   margin-top: 1em;
   min-width: 100%;
}

/* @media only screen and (max-width: 450px) {
   .loginPage .loginPanel {
      padding: 2em;
   }
} */

@media only screen and (max-width: 405px) {
   .loginPage .loginPanel {
      width: 300px;
   }
}


.loginPage .loginPanel .close-button {
   background-color: transparent;
   color: var(--secondary-fg-color);
   padding: 0;
   position: absolute;
   right: 0;
   top: 0;
   font-size: xx-large;
   border: none;
   width: 2em;
   height: 2em;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   text-align: center;
   margin-top: auto;
   margin-bottom: auto;
}

.loginPage .loginPanel .close-button::before {
   display: none;
}

.loginPage .loginPanel .forgot {
   display: block;
   margin: 1em 0 1em 0;
   text-align: left;
   font-size: large;
   color: var(--apple-link-color);
}
