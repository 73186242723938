@media screen {
   .CategoryPage {
      margin-bottom: 100px;
      padding-top: 95px;
      padding-bottom: 100px;
   }
   .CategoryPage img {
      margin: 0;
      width: 100%;
   }
}

.CategoryPage .ant-empty {
   background-color: var(--primary-bg-color);
   margin-top: 20px;
}

/* .CategoryPage .am-list-item {
   padding-left: 0em !important;
} */

.CategoryPage fieldset {
   margin: 20px 20px 20px 20px;
}

.CategoryPage fieldset a[role="button"] {
   margin-top: 0.5em;
}

.CategoryPage .bubbleList {
   display: flex;
   flex-wrap: wrap;
}

.CategoryPage  h2 {
   color: var(--primary-fg-color);
   font-weight: bolder;
   font-size: x-large;
   margin: 10px 0 0 20px;
   width:100%;
}


.CategoryPage .CategoryFrufru {
   cursor: pointer;
   width: 100vw;
   height: 100px;
   position: relative;
}

@media (max-width: 999px) {
   .CategoryPage .CategoryFrufru {
      flex-basis: 100%;
   }
}

@media (min-width: 1000px) {
   .CategoryPage .CategoryFrufru {
      flex-basis: 44%;
   }
}

@media (min-width: 1500px) {
   .CategoryPage .CategoryFrufru {
      flex-basis: 29%;
   }
}

@media (min-width: 2000px) {
   .CategoryPage .CategoryFrufru {
      flex-basis: 22%;
   }
}

@media (min-width: 2500px) {
   .CategoryPage .CategoryFrufru {
      flex-basis: 17%;
   }
}

@media (min-width: 3000px) {
   .CategoryPage .CategoryFrufru {
      flex-basis: 12%;
   }
}
