/* THESE ARE MOSTLY GENERIC FORM STYLINGS THAT COULD BE REUSED */

form .field {
   /* display: flex; */
   flex-wrap: nowrap;
   align-items: center;
   border-bottom: 1px solid var(--tertiary-fg-color); /* #ddd; */
   padding: 1em;
   margin-bottom: 5px;
   background-color: var(--secondary-bg-color);
   text-align: left;  /* since flex isn't working on mobile */
}

form label {
   font-size: large;
   font-weight: bold;
   margin-right: 0.5em;
   flex-grow: 0;
}

form input {
   padding: 0.5em;
   font-size: large;
   flex-grow: 1;

   /* margin-bottom: 0.5em; */

   border: 0;
   margin: 0;
}

form .field svg {
   width: 28px;
   height: 28px;
   color: var(--error-color);
   flex-shrink: 0;
   margin-left: 2px;
}

form .field textarea {
   resize: vertical;
   overflow: auto;
   flex-grow: 1;
}

@supports (selector(:has(*))) {
   form .field:has(input:invalid),
   form .field:has(textarea:invalid) {
      background-color: var(--form-input-invalid-background-color);
      color: red;
   }

   form .field:has(textarea:valid) svg,
   form .field:has(input:valid) svg {
      display: none;
   }

   form .field:has(textarea:invalid) svg,
   form .field:has(input:invalid) svg {
      display: block;
   }
}

@supports (not selector(:has(*))) {
   /*body { background-color: yellow !important; }*/
   form textarea:invalid,
   form input:invalid {
      background-color: var(--form-input-invalid-background-color) !important;
   }
   form .field svg {
      display: none !important;
   }
}

form fieldset {
   display: flex;
   flex-wrap: wrap;
   justify-content: center;
   margin-top: 30px;
   border: none;
   padding: 0;
}

form .submitButton:hover,
form .cancelButton:hover {
   background-color: var(--brand-bg-color);
}

form .submitButton,
form fieldset .adm-button {
   margin-bottom: 10px;
   min-width: 100%;
   height: 2.75em;
   font-size: large;
   border: 1px solid var(--secondary-fg-color);
   border-radius: 25px;
}

form .cancelButton {
   font-weight: bold;
}

form button[type="primary"]:valid,
form .submitButton:valid {
   border: 1px solid var(--apple-link-color);
   color: var(--apple-link-color);
   font-weight: bold;
}

.imageUploader {
   margin-top: 20px;
}

.adm-image-uploader {
   margin-top: 15px;
}

.adm-image-uploader-upload-button {
   border: 1px solid var(--tertiary-fg-color);
}

.adm-image-uploader-upload-button-icon {
   font-size: 48px;
}

.adm-image-uploader-cell-delete {
   width: 20px;
   height: 20px;
   font-size: 13px;
}

.adm-popover {
   -webkit-box-shadow: 0 0 10px #999;
   box-shadow: 0 0 10px #999;
}
