.article-list-header {
   display: flex;
   justify-content: space-between;

   padding: 10px;
}

.article-list-header h2 {
   margin: 0 !important;
}

.article-list-header {
   align-items: center;
}

.article-list-header .adm-badge {
   background-color: var(--brand-bg-color);
}

.article-list-header .adm-badge-content {
   font-size:small !important;
   padding: 2px 6px;
}

