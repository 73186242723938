@media screen {
    /* to accomodate for toolbar */
    .HelpfulsPage {
       margin-bottom: 100px;
       padding-top: 95px;
       padding-bottom: 0px;
    }
 }
 
.HelpfulsPage h3 {
    margin: 15px 15px 0 15px;
    color: var(--primary-fg-color);
    font-size: large;
}