.MorningTeaCard .cardContent {
    padding: 0;
 }
 
 .MorningTeaCard .body {
   border-bottom: 1px solid var(--tertiary-fg-color); /* #ddd;*/
 }

.MorningTeaCard .header .headerIcon svg {
   height: 40px !important;
   width: 40px !important;
   margin-right: 10px !important;
}

.MorningTeaCard .hiddenLink {
   display:none;
}