

.ShareActionSheet .action-sheet-button-list,
.ShareActionSheet .adm-list,
.ShareActionSheet .adm-list-body,
.ShareActionSheet .adm-list-body-inner,
.ShareActionSheet .ProfileListItem {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
 }

.ShareActionSheet .ShieldIcon {
   background-color: #000000;
   border-radius: 5px;
}


/* .ShareActionSheet .am-list-item .am-list-line .am-list-extra {
   position: absolute;
   right: 15px;
}

.ShareActionSheet .am-list-item .am-list-line .am-list-brief {
   margin-top: 0;
   font-size: 10pt;
} */
