/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
@media print {
   a[role="button"] {
      display: none;
   }
}
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

@media screen {
   .WhatsNewPage {
      margin-bottom: 100px;
      padding-top: 95px;
      padding-bottom: 70px;
   }
}

.WhatsNewPage .am-list-header {
   padding-top: 9px;
}

.WhatsNewPage .list-header {
   display: flex;
   justify-content: space-between;
   align-items: center;
}
