.SplashScreen {
   width: auto;
   height: 100vh;
   background-color: black;
   background-image: url("../images/fieldapp-splash-photo.jpg");
   background-position: center;
   background-repeat: no-repeat, no-repeat;
   background-color: #65abd0;
   background-size: auto 100vh;
   z-index: 20000;
}

.SplashScreen .logo {
   display: block;
   width: 75vw;
   max-width: 400px;
   margin: auto;
   padding-top: 5em;
}