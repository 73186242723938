@media (max-width: 450px) {
   .SolarCycleCalendarCard {
      max-width: 95vw !important;
   }
}
@media (max-width: 719px) {
   .SolarCycleCalendarCard {
      max-width: 95vw !important;
   }
}

.SolarCycleCalendarCard .calendarContent {
   padding: 0;
   min-height: 330px;
   overflow-x: clip;
   overflow-y: clip;
   width: 100%;
}

.SolarCycleCalendarCard .body {
   border-bottom: 0;
}
