.SolarCycleCalendarWidget {
   width: 100%;
   height: 100%;
   display: flex;
   flex-direction: column;
   flex-wrap: nowrap;
   margin-bottom: 0.5em;
   white-space: nowrap;
   min-width: 1280px;
}

.SolarCycleCalendarWidget .barGraphGradient {
   height: 160px;
}
