@media screen {
   .SettingsPage {
      margin-bottom: 100px !important;
      padding-top: 95px;
      padding-bottom: 100px;
   }
}

.SettingsPage h2 {
   margin: 15px;
   color: var(--primary-fg-color);
}

.SettingsPage .adm-divider-horizontal {
   margin: 0.333em;
}

.SettingsPage .adm-divider-horizontal::before,
.SettingsPage .adm-divider-horizontal::after {
   display: none;
}

.SettingsPage .PageSettings .adm-list-content {
   display: flex;
   justify-content: space-between;
   padding-left: 1em;
}

.SettingsPage .anticon {
   background-color: var(--secondary-bg-color);
}

.SettingsPage .PageSettings .anticon svg {
   height: 22px;
   width: 22px;
}

.SettingsPage .StartPagePicker .adm-list-item-content-main {
   display: flex;
   align-items: center;
}

.SettingsPage .StartPagePicker .adm-list-item-description {
   margin-left: auto;
   font-size: medium;
   align-self: baseline;
}

.SettingsPage .emailHelpLink {
   display: none;
}

.SettingsPage .storage {
   color: var(--secondary-fg-color);
   margin: 10px;
}

.SettingsPage .not-supported {
   font-size: small;
   margin: 10px;
}
.SettingsPage .debug {
   color: var(--secondary-fg-color);
   margin: 0 0 0 10px;
}

.ConfirmLogoutTitle {
   color: var(--primary-fg-color);
}