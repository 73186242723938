.BlueArmyRadioCard .cardContent {
   /*padding: 20px 15px 0 15px;*/
   padding: 0;
}

.BlueArmyRadioCard .am-card-header-content svg {
   height: 40px !important;
   width: 40px !important;
   margin-right: 10px !important;
}

.BlueArmyRadioCard .hiddenLink {
   display: none;
}
