.ImageOfMomentCard .body img {
   width: 100%;
}

.ImageOfMomentCard .body .summary {
   color: white;
   text-shadow: 2px 1px 3px black;
   text-align: center;
   margin-top: -2.5em;
}
