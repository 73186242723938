.ArticleOfMomentCard .body img {
   width: 100%;
}

.ArticleOfMomentCard .body .summary {
   color: var(--secondary-fg-color);
}

.ArticleOfMomentCard .body .article-content {
   padding: 10px 15px 0 15px;
   text-overflow: ellipsis;
   overflow: hidden;
   max-width: 100%;
   max-height: 200px;
   color: var(--primary-fg-color);
   border-bottom: 1px solid var(--tertiary-fg-color); /*#ddd;*/
}

.ArticleOfMomentCard .body .article-content h3 {
   font-family: "Linux Libertine", "Georgia", "Times", serif;
   color: var(--primary-fg-color);
   font-size: large;
   padding-bottom: 0.5em;
}

.ArticleOfMomentCard .body .article-content .summary {
   padding-bottom: 0.5em;
   font-style: italic;
}

.ArticleOfMomentCard .body .article-content .bodyText {
   overflow-y: clip;
}
