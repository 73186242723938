/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
@media print {
   .article-page a[role="button"] {
      display: none;
   }
   .article-page a,
   .article-body p {
      color: black;
   }
   fieldset {
      display: none;
   }
   .article-page {
      display: block !important;
      width: auto !important;
      height: auto !important;
      overflow: visible !important;
   }
   .article-page .featured-image {
      margin: 0;
      width: 200px;
   }
   .article-page .supplementalPhotos img {
      max-width: 200px;
   }
   .article-info-button,
   .article-media-player {
      display: none;
   }
   .ImagePopup .am-modal-button-group-v .am-modal-button {
      display: none;
   }
}
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

@media (prefers-color-scheme: light) {
   .article-page td.side,
   .article-page th {
      background-color: #ddddff;
      color: #0645ad;
   }
   .article-page {
      color: var(--primary-fg-color);
   }
   .article-body .wikitable td {
      border: 1px solid var(--primary-fg-color);
   }
   .article-body .wikitable tr:first-child td {
      background-color: #bbbebc;
   }
}

@media (prefers-color-scheme: dark) {
   .article-page td.side,
   .article-page th {
      background-color: #4e4f6a;
      color: #000000;
   }
   .article-page {
      color: var(--secondary-fg-color);
   }
   .article-body .wikitable td {
      border: 1px solid var(--tertiary-fg-color);
   }
   .article-body .wikitable tr:first-child td {
      background-color: var(--tertiary-bg-color);
   }
}

@media screen and (min-width: 1000px) {
   .article-page {
      margin: 20px 20px 20px 20px;
   }
}

@media screen and (prefers-color-scheme: light) {
   .article-page {
      background-color: var(--secondary-bg-color);
   }
}

@media screen and (prefers-color-scheme: dark) {
   .article-page h1,
   .article-page h2,
   .article-page h3 {
      color: var(--primary-fg-color);
   }
   .article-page {
      background-color: var(--primary-bg-color);
   }
}

@media screen {
   .article-page {
      margin-bottom: 100px;
      padding-top: 95px;
      padding-bottom: 20px;
   }
   .article-page .featured-image {
      margin: 0;
      width: 100%;
   }
   .article-info-button {
      background-color: #61678d; /*var(--tertiary-bg-color);*/
      border: 1px solid var(--tertiary-fg-color);
      border-radius: 10px;
      width: 100%;
      max-width: 400px;
   }
}

.audio-player {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-self: baseline;
}

.audio-player a {
   font-size: x-large;
   align-self: center;
}

.am-modal-wrap {
   margin-top: 95px;
}

.ImagePopup .am-modal-content {
   background-color: var(--primary-bg-color);
}

.ImagePopup .am-modal-body img {
   width: 100%;
}

.ImagePopup .am-modal-footer {
   position: absolute;
   top: 10px;
   right: 20px;
}

.ImagePopup .am-modal-button-group-v .am-modal-button {
   color: var(--secondary-fg-color);
   background-color: var(--primary-bg-color-50pct);
   border-radius: 25px;
   font-size: xx-large;
   width: 1.5em;
   height: 1.5em;
   line-height: 1.5em;
}

.article-page .summary {
   font-style: italic;
   margin-bottom: 14px;
}

.article-page h1,
.article-page h2,
.article-page h3 {
   font-family: "Linux Libertine", "Georgia", "Times", serif;
   font-weight: normal;
   border-bottom: 1px solid #a2a9b1;
   margin-left: 1em;
   margin-right: 1em;
}

.article-page .audio-player-wrapper {
   position: relative;
   padding-top: 70px;
   margin: 0 20px 0 20px;
}

.article-page .video-player-wrapper {
   position: relative;
   padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
   margin: 0 0 0 0;
}

.article-page .react-player {
   position: absolute;
   top: 0;
   left: 0;
}

.article-page .sorry-offline {
   display: block;
   background-image: linear-gradient(#a63b2b, #802f23);
   height: 70px;
   text-align: center;
   color: var(--toolbar-fg-color);
   line-height: 5em;
}

.article-page h1 {
   line-height: 1.3;
   font-size: 1.8em;
   color: var(--primary-fg-color);
}

.article-page h2 {
   line-height: 1.3;
   font-size: 1.5em;
   margin-top: 1em;
   color: var(--secondary-fg-color);
}

.article-page h3 {
   line-height: 1.3;
   font-size: 1em;
   margin-bottom: 0.5em;
   color: var(--tertiary-fg-color);
}

.article-page > h1,
.article-page > h2,
.article-page > h3 {
   margin-left: 20px;
   margin-right: 20px;
}

.article-body p {
   line-height: 1.6;
   font-family: sans-serif; /*"Linux Libertine", "Georgia", "Times", serif;*/
   font-weight: normal;
}

.article-content {
   padding: 20px;
   background-color: transparent;
}

.article-page .related-box {
   border: 1px solid var(--secondary-fg-color);
   padding: 3px;
   margin: 20px;
   background-color: transparent;
}

.article-page .related-box a {
   color: var(--apple-link-color);
}

.article-body .wikitable {
   border: 1px solid var(--primary-fg-color);
   border-collapse: collapse;
}

.article-body .wikitable td {
   background-color: var(--secondary-bg-color);
   padding: 3px;
}

.article-body .wikitable tr:first-child td {
   font-weight: bold;
}

.article-page .related-box table {
   border-spacing: 0;
}

.article-page .related-box tr {
   text-align: center;
   line-height: 1.6;
}

.article-page .related-box td.side,
.article-page .related-box th {
   /* background-color: #ddddff;
   color: #0645ad; */
   /*white-space: nowrap;*/
   text-align: right;
   font-weight: bold;
}

.article-page .related-box td {
   width: 60%;
   text-align: left;
   padding: 5px;
   border-top: 1px solid var(--tertiary-bg-color);
}

.article-page .related-box td.side {
   width: 40%;
}

.article-page .related-box th {
   text-align: center;
   padding: 5px;
}

.article-page fieldset {
   margin: 20px;
}

.article-page fieldset a[role="button"] {
   cursor: pointer;
   margin-top: 0.5em;
}

.article-page .heading {
   display: flex;
   justify-content: space-between;
   margin: .67em 0;
}

.article-page .supplementalPhotos {
   display: flex;
}

.article-page .supplementalPhotos img {
   display: block;
   max-width: 50vw;
   max-height: 50vw;
   /* width: auto; */
   height: auto;
   margin: 5px;
   width:50%;
}
