.close-button {
   background-color: transparent;
   color: var(--secondary-fg-color);
   padding: 0;
   font-size: xx-large;
   border: none;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   text-align: center;
   margin-top: auto;
   margin-bottom: auto;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
   html:not([data-scale]) .am-button.close-button  {
      position: normal;
   }
}

.close-button::before {
   display: none;
}
