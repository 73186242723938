.Card {
   border-radius: 15px;
   margin: 15px 15px 25px 15px;
   display: flex;
   flex-direction: column;
}

@media (prefers-color-scheme: light) {
   .Card {
      box-shadow: 0 0 15px 0px rgba(0, 0, 0, 0.1);
   }
}

@media (prefers-color-scheme: dark) {
   .Card {
      background-color: var(--primary-bg-color) !important;
   }
}

.Card,
.Card .body,
.Card .footer {
   background-color: var(--secondary-bg-color);
   color: var(--secondary-fg-color);
}

.Card .body {
   padding: 0;
   flex-grow: 1;
   border-top: 1px solid var(--tertiary-fg-color); /* #ddd; */
}

.Card .header {
   height: 3.5em !important;
   display: flex;
   white-space: nowrap;
   align-items: center;
   justify-content: flex-start;
}

.CardExplorerPage .title {
   flex-grow: 2;
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   font-size: 14pt;
   color: var(--primary-fg-color);
}

.Card .header .headerIcon {
   flex-grow: 0;
   margin-left: 5px;
}

.Card .header .headerIcon svg {
   height: 32px;
   width: 32px;
   margin-right: 15px;
   fill: #9166c0;
   /*fill: #a6b279;*/
}

.Card .header .title .subheading {
   font-size: smaller;
   color: var(--secondary-fg-color);
}

.Card .header .menuButton {
   flex-grow: 0;
   cursor: pointer;
   border: 0;
   /* margin-right: 1em; */
   background-color: transparent;
}

.Card .header .menuButton .anticon-ellipsis svg {
   color: var(--apple-link-color);
   height: 26px;
   width: 26px;
}

.Card .body img {
   width: 100%;
}

.Card .footer {
   text-align: right;
   font-size: medium;
   margin: 0;
   padding: 0 15px 0 15px;
   border-bottom-left-radius: 15px;
   border-bottom-right-radius: 15px;
}

.Card .smoresButton {
   color: var(--apple-link-color);
   cursor: pointer;
   border: none;
   background-color: transparent;
   text-decoration: none;
   padding: 0;
   white-space: nowrap;
}
