

.MainPageActionSheetMenu h3 {
   padding-top: 1em;
   margin-left: 1em;
   font-size: large;
}

.MainPageActionSheetMenu .title {
   text-align: left;
   margin: 0 0 5px 15px;
   color: var(--primary-fg-color);
}

.MainPageActionSheetMenu .hamburgler-icon {
   width: 22px;
   height: 22px;
   margin-right: 10px;
   fill: inherit;
}

.MainPageActionSheetMenu  .ShareIcon {
   width: 30px;
   height: 30px;
   margin-left: -3px;
   fill: inherit;
   margin-bottom: 5px;
}