.AlphabetList {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    position: fixed;
    top: 55%;
    transform: translate(-50%,-50%);
    right: -20px;
    z-index: 4000;
    /* background-color:red; */
}

.AlphabetList ul {
   margin: 0;
}

.AlphabetList li {
    color: blue;
    padding: 0;
    text-align: center;
    list-style-type: none;
    margin-right: 0px;
    width: 3em;
/* background-color:yellow; */
    -webkit-user-select: none; /* Safari */
            user-select: none;
    cursor:pointer;

    /* width: 1em;
    height: 1em;
    font-size: small; */
}

.AlphabetList .hidden {
    display:none;
}