.CategoryFrufru {
   display: flex;
   align-items: center;
   align-content: flex-start;

   margin: 10px 15px 15px 15px;

   padding: 0 15px 0 15px;
   border-radius: 10px;
}

.CategoryFrufru h3 {
   color: var(--toolbar-fg-color);
   font-weight: bolder;
   font-size: large;
   z-index: 100;
   text-overflow: ellipsis;
   white-space: nowrap;
   text-shadow: 1px 1px 5px #000000;
}

.CategoryFrufru img {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100px;
   object-fit: cover;

   display: block;
   filter: brightness(70%);
   border-radius: 10px;
}
