/* transitions, see https://reactcommunity.org/react-transition-group/  */

.fade-enter {
    opacity: 0;
 }
 
 .fade-enter-active {
    opacity: 1;
    transition: opacity 2000ms;
 }
 
 .fade-exit {
    opacity: 1;
 }
 
 .fade-exit-active {
    opacity: 0;
    transition: opacity 2000ms;
 }
 
 /* ----------------------------- */
 /* start: offscreen-right, slide left;  end: onscreen */
 /*
 .slide-enter {
    transform: translate(100%);
 }
 .slide-enter-active {
    transform: translate(0%);
    transition: transform 2000ms ease-in-out;
 }
     start: onscreen, slide right?; end: offscreen-right
 .slide-exit {
    transform: translate(0%);
 }
 .slide-exit-active {
    transform: translate(100%);
    transition: transform 2000ms ease-in-out;
 }
 */
 /* ----------------------------- */
 /* start: offscreen-left, slide right; end: onscreen */
 /*
 .slideback-enter {
    transform: translate(-100%);
 }
 .slideback-enter-active {
    transform: translate(0%);
    transition: transform 2000ms ease-in-out;
 }
 .slideback-exit {
    transform: translate(0%);
 }
 .slideback-exit-active {
    transform: translate(-100%);
    transition: transform 2000ms ease-in-out;
 }
 */
 
 /* ----------------------------- */
 
 .slide-enter {
    transform: translateX(100vw);
    opacity: 0;
    position: absolute;
 }
 
 .slide-enter-active {
    transform: translateX(0);
    opacity: 1;
    transition: all 300ms ease-in-out;
 }
 
 .slide-exit {
    transform: translateX(0);
    opacity: 1;
 }
 
 .slide-exit-active {
    transform: translateX(-100vw);
    opacity: 0;
    transition: all 300ms ease-in-out;
 }
 
 /* ----------------------------- */
 
 .slideback-enter {
    transform: translateX(-100vw);
    opacity: 0;
    position: absolute;
 }
 
 .slideback-enter-active {
    transform: translateX(0);
    opacity: 1;
    transition: all 300ms ease-in-out;
 }
 
 .slideback-exit {
    transform: translateX(0);
    opacity: 1;
 }
 
 .slideback-exit-active {
    transform: translateX(100vw);
    opacity: 0;
    transition: all 300ms ease-in-out;
 }
 
 /* ----------------------------- */
 
 .slideup-enter {
    transform: translateY(100vw);
    opacity: 0;
    position: absolute;
 }
 
 .slideup-enter-active {
    transform: translateY(0);
    opacity: 1;
    transition: all 300ms ease-in-out;
 }
 
 .slideup-exit {
    transform: translateY(0);
    opacity: 1;
 }
 
 .slideup-exit-active {
    transform: translateY(-100vw);
    opacity: 0;
    transition: all 300ms ease-in-out;
 }
 