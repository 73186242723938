.AdminCategoryPage {
   padding: 95px 10px 10px 10px;
   margin-bottom: 75px;
}

.AdminCategoryPage h2 {
   color: var(--primary-fg-color);
   margin-top: 0;
}

/* ================================= */

.AdminCategoryPage .instruction,
.AdminCategoryPage .errorText {
   font-size: medium;
}


@supports (selector(:has(*))) {
   .AdminCategoryPage #categoryEditorForm .field input:invalid,
   .AdminCategoryPage #categoryEditorForm .field:has(input:invalid) {
      background-color: var(--form-input-invalid-background-color);
   }

   .AdminCategoryPage
      #categoryEditorForm
      .field:has(input:valid)
      .antd-mobile-icon {
      display: none;
   }

   .AdminCategoryPage
      #categoryEditorForm
      .field:has(input:invalid)
      .antd-mobile-icon {
      display: block;
      color: red;
      width: 28px;
      height: 28px;
      margin-left: 2px;
   }
}

.AdminCategoryPage .instruction {
   color: var(--secondary-fg-color);
}

#categoryEditorForm {
   padding: 10px;
   min-width: 300px;
   max-width: 600px;
}
