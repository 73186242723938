@media print {
   .MainSearch {
      display: none;
   }
}

@media (prefers-color-scheme: dark) {
   .MainSearch .am-search-clear {
      background-image: url('data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20viewBox%3D%270%200%2028%2028%27%3E%3Ccircle%20cx%3D%2714%27%20cy%3D%2714%27%20r%3D%2714%27%20fill%3D%27%23ccc%27%2F%3E%3Cline%20stroke%3D%27%23000000%27%20stroke-width%3D%272%27%20stroke-miterlimit%3D%2710%27%20x1%3D%278%27%20y1%3D%278%27%20x2%3D%2720%27%20y2%3D%2720%27%2F%3E%3Cline%20fill%3D%27none%27%20stroke%3D%27%23000000%27%20stroke-width%3D%272%27%20stroke-miterlimit%3D%2710%27%20x1%3D%2720%27%20y1%3D%278%27%20x2%3D%278%27%20y2%3D%2720%27%2F%3E%3C%2Fsvg%3E');
   }
}

.MainSearch {
   background-color: transparent;
   padding-bottom: 6px;
}

.MainSearch .adm-search-bar {
   background-color: inherit;
   padding: 5px 10px 0 10px;
}

.MainSearch .adm-search-bar .adm-search-bar-input-box,
.MainSearch .adm-search-bar .adm-search-bar-input-box .adm-search-bar-input {
   border-radius: 10px;
}

.MainSearch .adm-search-bar input[type="search"] {
   line-height: unset !important;
   outline-offset: unset !important;
}

/* .adm-popover-inner .adm-popover-inner-content {
   padding-left: 0em;
   padding-right: 0em;
} */

 /* .adm-popover-inner-content .am-list-item .am-list-thumb:first-child {
   margin-right: 0.5em !important;
 } */



/** LEGIT */
.MainSearch .adm-search-bar-suffix span {
   color: var(--apple-link-color);
}

.MainSearchPopover,
.MainSearchPopover .adm-popover-inner {
   width: 100vw !important;
}

.MainSearchPopover .NoResults .instructions {
   color: var(--primary-fg-color);
}

.adm-search-bar-input input[type="search"] {
   color: var(--primary-fg-color);
   height: 32px;
}

.MainSearch .adm-search-bar-input-box,
.MainSearch .adm-search-bar-input {
   background-color: var(--primary-bg-color);
   height: 32px;
}
/** LEGIT */



/* .MainSearch .am-search-input .am-search-synthetic-ph {
   height: 32px;
   line-height: 32px;
} */

/* .MainSearch .am-search-clear {
   width: 20px !important;
   height: 20px !important;
   //padding: 3px !important;
   background-size: 20px 20px !important;
   margin-top: auto;
   margin-bottom: auto;
} */

/* .MainSearch .am-popover-mask {
   top: 100px !important;
} */

.MainSearchPopover {
   z-index: 4250 !important;
}

.MainSearchPopover .ArticleListItem .title {
   color: var(--primary-fg-color);
}

.recent-search-header {
   display: flex;
   justify-content: space-between;
   align-items: center;
}

.recent-search-header div {
   display: flex;
   justify-content: space-between;
   align-items: center;
   cursor: pointer;
}
.recent-search-header div span {
   margin-left: 5px;
   display: inline-block;
}

