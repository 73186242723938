/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
@media print {
   a[role="button"] {
      display: none;
   }
}
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

@media screen {
   .ResequenceArticlesPage {
      margin-bottom: 100px;
      padding-top: 95px;
      padding-bottom: 70px;
   }
}

.ResequenceArticlesPage .adm-button {
   background-color: var(--brand-bg-color);
   color: #f2f2f8;
}

.ResequenceArticlesPage .NoResults {
   margin: 0 20px 0 20px;
}

.ResequenceArticlesPage .adm-list-header {
   padding-top: 9px;
}

.ResequenceArticlesPage .list-header {
   display: flex;
   justify-content: space-between;
   align-items: center;
}

.ResequenceArticlesPage .list-header h3 {
   margin: 0;
}

.ResequenceArticlesPage .editable-row {
   background-color: var(--secondary-bg-color);
   border-bottom: 1px solid var(--tertiary-fg-color); /* #dedede; */
   margin-left: 1em;
}

.ResequenceArticlesPage .fdraglist {
   background-color: #646764;
}

.ResequenceArticlesPage .editable-row {
   display: flex;
   justify-content: flex-start;
   align-items: center;
   flex-wrap: nowrap;
   height: 50px;
   /* padding-bottom: 10px; */
}

.ResequenceArticlesPage span[role="img"] svg {
   width: 22px;
   height: 22px;
}

/* hamburgler icon */
.ResequenceArticlesPage .hamburgler-icon {
   width: 17px;
   height: 17px;
   flex-grow: 0;
   margin-left: 10px;
   margin-right: 15px;
   fill: var(--tertiary-fg-color);
}

.ResequenceArticlesPage .editable-row .edit-text {
   flex-grow: 10;
   overflow-x: hidden;
}

.ResequenceArticlesPage .fdrag_item {
   padding-bottom: 5px;
}
