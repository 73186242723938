/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
@media print {
   .CardExplorerPage .adm-card-footer-extra,
   .CardExplorerPage a[role="button"] {
      display: none;
   }
   .CardExplorerPage svg {
      width: 200px;
   }
}
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

/*
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
   html:not([data-scale])
      .CardExplorerPage
      .adm-card:not(.adm-card-full)::before {
      display: none !important;
   }
}
*/

@media screen {
   /* to accomodate for toolbar */
   .CardExplorerPage {
      margin-bottom: 100px;
      padding-top: 95px;
      padding-bottom: 60px;
   }
}

.CardExplorerPage {
   display: flex;
   flex-wrap: wrap;
   justify-content: space-evenly;
}

@media (max-width: 390px) {
   .CardExplorerPage .Card {
      max-width: 90%;
   }
}

@media (max-width: 450px) {
   .CardExplorerPage .Card {
      max-width: 100%;
   }
}

@media (max-width: 719px) {
   .CardExplorerPage .Card {
      flex-basis: 100%;
   }
}

@media (min-width: 720px) {
   .CardExplorerPage .Card {
      /*flex-basis: 41%;*/
      width: 41%;
   }
}

@media (min-width: 1040px) {
   .CardExplorerPage .Card {
      /*flex-basis: 27%;*/
      width: 27%;
   }
}

@media (min-width: 1560px) {
   .CardExplorerPage .Card {
      /*flex-basis: 19%;*/
      width: 19%;
   }
}

