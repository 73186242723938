
.ArticleAudioPage {
    margin-top: 100px;
    padding-bottom: 100px;
    display: flex;
    flex-direction: column;
    background-color: var(--primary-bg-color);
}

.ArticleAudioPage .default-cover {
   font-size: 10em;
   line-height: 0px;
   margin: 1em auto 1em auto;
}

.ArticleAudioPage .album-cover {
    margin: 1em auto 1em auto;
    width: 90vw;
    max-width: 600px;
    border-radius: 25px;
}

.ArticleAudioPage .control-space {
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--secondary-bg-color);
    width: 100vw;
    padding: 1em 0 2em 0;
}

.ArticleAudioPage .control-space h3 {
   font-size: x-large;
   margin-top: 0;
}

.ArticleAudioPage .control-space ol {
   padding: 0;
}

.ArticleAudioPage .control-space ol li {
    list-style-type: none;
}

.ArticleAudioPage .control-space ol li .dancing-bars-icon {
    display: inline-block;
    min-width: 20px;
    max-width: 20px;
    min-height: 20px;
    margin-bottom: -3px;
    /* background-color: black; */
    /* filter: brightness(0) saturate(100%) invert(59%) sepia(76%) saturate(2317%) hue-rotate(76deg) brightness(109%) contrast(123%); */
    /* transform: rotate(90deg);
    clip-path: inset(25px 35px 25px 35px);
    position:relative;
    bottom: -30px;
    left: -30px; */
}


.ArticleAudioPage .control-space p {
   margin-top: -20px;
   color: var(--secondary-fg-color);
}

.ArticleAudioPage .control-space ol {
   color: var(--primary-fg-color);
   margin-top: -10px;
}

/** track listing item */
.ArticleAudioPage .control-space ol li button {
   border-color: transparent;
   height: 2em;
   font-size: medium;
   color: var(--apple-link-color);
}

/** transport controls */
.ArticleAudioPage .control-space ol li button:not(:hover) {
    background-color: transparent;
    border-color: transparent;
}

/** transport control container */
.ArticleAudioPage .transportControls {
    display: flex;
    align-items:center;
    justify-content: space-around;
    width: 100vw;
    margin: 1em;
}

.ArticleAudioPage .transportControls .anticon svg {
    color: var(--primary-fg-color);
}

.ArticleAudioPage .transportControls button:disabled .anticon svg {
    color: dimgray;
}

.ArticleAudioPage .transportControls .anticon-unordered-list svg {
   transform:rotate(180deg);
}

.ArticleAudioPage .transportControls button,
.ArticleAudioPage .transportControls button:disabled,
.ArticleAudioPage .transportControls button:not(:hover) {
   border-color: transparent;
   background-color: transparent;
}

.ArticleAudioPage .transportControls .anticon-unordered-list svg,
.ArticleAudioPage .transportControls .anticon-step-backward svg,
.ArticleAudioPage .transportControls .anticon-step-forward svg,
.ArticleAudioPage .transportControls .anticon-copy svg {
    width: 28px;
    height: 28px;
}

.ArticleAudioPage .transportControls .anticon-play-circle svg,
.ArticleAudioPage .transportControls .anticon-pause-circle svg {
    width: 40px;
    height: 40px;
}

.ArticleAudioPage .audio-player {
    margin-left: 1em;
    margin-right: 1em;
}

