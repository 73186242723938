.ToolBar {
   position: fixed;
   left: 0;
   bottom: 0;
   width: 100%;
   height: 60px;

   display: flex;
   justify-content: space-evenly;
   align-items: center;
   flex-wrap: nowrap;

   height: 60px;
   color: var(--toolbar-fg-color);
   fill: var(--toolbar-fg-color);

   z-index: 4000;
}

.ToolBar.online {
   background-color: var(--brand-bg-color);
 }
 
 .ToolBar.offline {
    background-image:  linear-gradient(#a63b2b,#802f23);
 }
 
.selected-icon {
   color: #f3a954 !important;
   fill: #f3a954 !important;
}

.am-tab-bar span[role="img"],
.am-tab-bar svg,
.ToolBarButton,
.ToolBarButton svg {
   width: 32px;
   height: 32px;
   color: inherit;
   fill: inherit;
}
