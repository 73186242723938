.SolarCycleCalendarPage {
   margin-bottom: 100px;
   padding-top: 95px;
   padding-bottom: 0px;
   display: flex;
   flex-direction: column;
}

.SolarCycleCalendarPage .calendarContent {
    min-height: 340px;
    overflow-x: scroll;
    overflow-y: clip;
    width: 100%; 
    display: flex;
}

.SolarCycleCalendarPage .SolarCycleCalendarWidget .barGraphGradient {
    top: 10.5em;
}

.SolarCycleCalendarPage .searchBar {
    background-color: var(--primary-bg-color);
    display: flex;
    padding: 0.5em;
    justify-content: center;
    align-items: center;
}

.SolarCycleCalendarPage .searchBar > .label {
    margin-right: 0.5em;
    font-weight: bold;
    flex: 0 0 auto;
}
 
.SolarCycleCalendarPage .searchBar > .ant-input-search-with-button {
    flex: 0 1 auto;
    width:  10em;
}

.SolarCycleCalendarPage .searchBar > .ant-input-search-with-button input {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.SolarCycleCalendarPage .searchBar .ant-input-group-addon,
.SolarCycleCalendarPage .searchBar button,
.SolarCycleCalendarPage .searchBar .anticon-search {
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
}