.NoResults {
   width: 100vw;
   padding-top: 15px;
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   align-items: center;
   overflow-x: clip;
}
.NoResults span[role="img"] {
   font-size: 80pt;
}
.NoResults .instructions {
   text-align: center;
}
