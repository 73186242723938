@media screen {
   .CardExplorerSettingsPage {
      margin-bottom: 100px;
      padding-top: 95px;
      padding-bottom: 70px;
   }
}

.CardExplorerSettingsPage .adm-list-header {
   padding-top: 9px;
}

.CardExplorerSettingsPage .adm-list-header h2 {
   color: var(--primary-fg-color);
}

.adm-switch {
   --checked-color: green;
}

/* .CardExplorerSettingsPage .list-header {
   display: flex;
   justify-content: space-between;
   align-items: center;
} */

.CardExplorerSettingsPage .fdraglist {
   background-color: #646764;
}

.CardExplorerSettingsPage .editable-row {
   display: flex;
   justify-content: flex-start;
   align-items: center;
   flex-wrap: nowrap;
   height: 50px;
   padding: 0 10px 0 10px;
   border-bottom: 1px solid var(--tertiary-fg-color); /* #ddd; */
}

.CardExplorerSettingsPage .editable-row,
.CardExplorerSettingsPage .fdrag_item_inner {
   background-color: var(--secondary-bg-color);
}

.CardExplorerSettingsPage .delete-button {
   height: 100%;
   display: flex;
   align-items: center;
}

/* delete icon */
.CardExplorerSettingsPage .circle-minus-delete-icon {
   flex-grow: 0;
   margin-left: 10px;
   margin-right: 10px;
   color: #c6373b;
   background-color: white;
   border-radius: 15px;
}

.CardExplorerSettingsPage span[role="img"] svg {
   width: 22px;
   height: 22px;
}

/* hamburgler icon */
.CardExplorerSettingsPage .hamburgler-icon {
   width: 17px;
   height: 17px;
   flex-grow: 0;
   margin-left: 10px;
   margin-right: 30px;
   fill: var(--tertiary-fg-color);
}

.CardExplorerSettingsPage .hamburgler-icon.disabled {
   display:none !important;
}

.CardExplorerSettingsPage .editable-row .edit-text {
   flex-grow: 10;
   overflow-x: hidden;
}
